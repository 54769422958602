<template>
  <main>
    <v-card flat>
      <v-card-title>
        <div>
          Projects List

          <v-btn icon class="ml-3" @click="getProjects" :disabled="projects.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          disabled
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': projects.loading
          }"
          show-expand
          single-expand
          :expanded.sync="projects.expanded"
          item-key="_id"
          :page="projects.meta.page"
          :items="projects.list"
          :options.sync="projects.options"
          :server-items-length="projects.meta.total || 0"
          :loading="projects.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-list>
                <v-list-item>
                  <span class="font-weight-bold mr-2">Website:</span>
                  <a v-if="item.website" :href="item.website" target="_blank">{{item.website}}</a>
                  <span v-else>Not specified</span>
                </v-list-item>
                <v-list-item>
                  <span class="font-weight-bold mr-2">Description:</span>
                  <span class="project-description">{{item.description || 'Not specified'}}</span>
                </v-list-item>
              </v-list>
            </td>
          </template>

          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link dense @click="editProject(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFileEdit }}</v-icon>
                    <span>Edit Project</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiReload, mdiDotsVertical, mdiFileEdit } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

import { eventBus } from '@/utils/eventBus'

export default {
  data: () => ({
    icons: {
      mdiReload,
      mdiDotsVertical,
      mdiFileEdit,
    },
    projects: {
      expanded: [],
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  created() {
    eventBus.$on('project-builder-update', () => {
      this.getProjects({ page: 1 })
    })
  },
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    tableColumns() {
      return [
        { text: 'CREATED', value: 'createdAt', sortable: false },
        { text: 'NAME', value: 'name', sortable: false },
        { text: 'TYPE', value: 'type', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'OWNER', value: 'owner.wallet', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ]
    },
  },
  methods: {
    getProjects(query) {
      this.projects.loading = true

      const params = {
        limit: this.projects.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('admin/projects', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.$set(this.projects, 'meta', meta)
          // this.projects.meta = meta
          this.projects.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.projects.loading = false
        })
    },

    editProject(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'project-builder',
        modalAttrs: {
          persistent: true,
          'max-width': 1200,
        },
        componentAttrs: {
          projectData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    changePagination(pagination) {
      this.getProjects({
        page: pagination.page,
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },
}
</script>

<style scoped lang="scss">
.v-list-item {
  min-height: auto !important;
  align-items: stretch;
}

.project-description {
  word-break: break-word;
  white-space: normal;
}
</style>